import { MissionMode, RewardsInfo, RewardsType } from "@/type"

export function getRewardsColor(type: RewardsType) {
    if (type === 'tcoin') {
        return '#E6A529'
    } else if (type === 'energy') {
        return '#B429E6'
    } else if (type === 'xp') {
        return '#E6A529'
    }
}

export function getRewardsForMode (missionMode: MissionMode): RewardsType {
    const dict: Record<MissionMode, RewardsType> = {
        'train': 'xp',
        'rest': 'energy',
        'mission': 'tcoin'
    }
    return dict[missionMode]
}

export function getEnergyWithRewards (energy: number, 
                                      rewards: RewardsInfo, 
                                      mode: MissionMode | null, 
                                      maxEnergy: number): number {
    if (rewards) {
        return getEnergyBase(energy, rewards.rewards,rewards.energyCost ,mode, maxEnergy)
    }
    return energy ? energy : 0
}

export function getEnergyBase (energy: number, 
                              rewardsAmount: number | null, 
                              energyCost: number | null,
                              mode: MissionMode | null, 
                              maxEnergy: number) {
    if (mode === 'rest' && rewardsAmount) {
        return Math.min(energy + rewardsAmount, maxEnergy)
    } else if (modeConsumeEnergy(mode) && energyCost) {
        return Math.max(energy - energyCost, 0)
    }
    return energy ? energy : 0
}

export function getEnergyInfoWithRewards (energy: number, 
                                          rewards: RewardsInfo, 
                                          mode: MissionMode | null): string {
    if (rewards) {
        return getEnergyInfoBase(energy, rewards.rewards,rewards.energyCost ,mode)
    }
    return `${energy}`                                           

}

export function getEnergyInfoBase (energy: number, 
                                rewardsAmount: number, 
                                energyCost: number,
                                mode: MissionMode | null) {
    if (mode === 'rest') {
        return `Real energy ${energy}. To harvest : ${rewardsAmount}`
    } else if (modeConsumeEnergy(mode)) {
        return  `Real energy ${energy}. To consume after ${mode} : ${energyCost}`
    }
    return `${energy}`   
}

function modeConsumeEnergy (mode: MissionMode | null) {
    return mode && ['train', 'mission'].includes(mode)
}
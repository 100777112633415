




















import { MissionMode, RewardsType } from '@/type';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getRewardsColor } from '../utils/rewardsHelper'

@Component
export default class RewardsIcon extends Vue {
    @Prop()
    amount!: number

    @Prop()
    type!: RewardsType

    @Prop()
    negative!: boolean

    @Prop()
    convertToK!: boolean

    @Prop({default: 'block'})
    unit!: string

    @Prop()
    imgHeight!: string

    maxTrooperz = this.$config?.nbTrooperz

    energyImg = require('../assets/stats/Stat_energy.png')
    xpImg = require('../assets/stats/Stat_xp.png')
    tcoinImg = require('../assets/tcoins/LOGO-TCOIN.png')

    currentRewardsIs(type: RewardsType) {
        return this.type === type
    }

    getColor(type: RewardsType) {
        return getRewardsColor(type)
    }

    getStyle(type: RewardsType) {
        return 'color: ' + this.getColor(type) + ';'
    }

    getCurrentRewardsTypeStr () {
        if (this.currentRewardsIs('energy')) {
            return 'Energy'
        } else if (this.currentRewardsIs('xp')) {
            return 'Xp'
        } else if (this.currentRewardsIs('tcoin')) {
            return 'TCoin'
        }
        console.warn('Can not find rewards type str')
    }

    getHeight () {
        if (this.imgHeight) {
            return 'height: ' + this.imgHeight + ';'
        }
        return ''
    }

    get displayAmount () {
        if (this.convertToK && this.amount) {
            let r = Math.pow(10, 1)
            let val = Math.round((this.amount / 1000) * r) / r
            return val + 'k'
        }
        return this.amount
    }

    get sign () {
        return this.negative ? '-' : '+'
    }

}
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class TcoinBalance extends Vue {
    @Prop()
    tcoinBalance!: number

    tcoinImg = require('../assets/tcoins/LOGO-TCOIN.png')
}

import Vue from 'vue'
import Vuex from 'vuex'
import { DepositInfo } from '@/type';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    address: null as string | null,
    tcoinBalance: null as number | null,
    depositInfos: {} as Record<string, DepositInfo>
  },
  getters: {
    getAddress (state: any) {
      return state.address
    },
    getTcoinBalance (state: any) {
      return state.tcoinBalance
    },
    getDepositInfos (state: any) {
      return state.depositInfos
    }
  },
  mutations: {
    setAddress (state: any, address: string) {
      Vue.set(state, 'address', address)
    },
    setTcoinBalance (state: any, balance: number) {
      Vue.set(state, 'tcoinBalance', balance)
    },
    setDepositInfos (state: any, depositInfos: number) {
      Vue.set(state, 'depositInfos', depositInfos)
    }
  },
  actions: {
  },
  modules: {
  }
})

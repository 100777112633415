




















import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import RewardsIcon from '@/components/RewardsIcon.vue';

@Component({
    components: {
        RewardsIcon
    }
})
export default class Acknowledgment extends Vue {
    @PropSync('active', {type: Boolean})
    activeDialog!: boolean

    ack = false

    text = `
        Trooperz Club is a in beta and I understand that i use it at my own risk. 
        Any losses incurred due to my actions are my own responsibility.
    `

    setAck () {
        this.ack = !this.ack
        if (this.ack) {
            localStorage.setItem('ack', '1')
            window.setTimeout(() => {
                this.activeDialog = false
                this.ack = false
            }, 500)
        }
    }
}

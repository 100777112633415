import { BigMapKeyTzkt, BigMapTzKt, BalanceTzkt, TrooperzMetadata, TrooperzAttributes, NftMetadata } from "@/type";
import axios from "axios";


export async function getTokenBalanceOfUser(address: string, network: string, contract: string): Promise<{data: BalanceTzkt[]}> {
    const url = createUrl(address, network, contract)
    return await axios.get(url)
}

export async function getTokenBalanceOfUserMinimal(address: string, network: string, contract: string): Promise<{data: string[]}> {
    const url = createUrlNftBalanceMinimal(address, network, contract)
    return await axios.get(url)
}

export async function getBigMapsOfContract (network: string, contract: string, limit?: number, offset?: number): Promise<BigMapTzKt[]> {
    const url = createUrlGetBigMaps(network, contract, limit, offset)
    return (await axios.get(url)).data
}


export async function getBigMap<T> (network: string, bigMapId: number, limit=50, offset=0): Promise<BigMapKeyTzkt<T>[]> {
    const url = createUrlGetBigMap(network, bigMapId, limit, offset)
    return (await axios.get(url)).data
}

export async function getBlockCount (network: string) {
    const url = createUrlBlockCount(network)
    return (await axios.get(url)).data
}

export async function getTokenInfo (network: string, contract: string, tokenId: number): Promise<{totalMinted: string, totalSupply: string}[]|null> {
    const url = createUrlGetTokenMetadata(network, contract, tokenId)
    return (await axios.get(url)).data
}

export async function getTokenMetadata (network: string, contract: string, tokenId: number): Promise<{tokenId: string, metadata: NftMetadata}[]|null> {
    const url = createUrlGetTokenMetadata(network, contract, tokenId)
    return (await axios.get(url)).data
}

export async function getAllTokenMetadata (network: string, contract: string): Promise<{tokenId: string, metadata: NftMetadata}[]|null> {
    const url = createUrlGetAllTokenMetadata(network, contract)
    return (await axios.get(url)).data
}

export async function getGetInBigMap<T> (network: string, contract: string,name: string, keys: number | string): Promise<{value: T, key: string, active: boolean}> {
    const url = createUrlGetInBigMap(network, contract, name, keys)
    return (await axios.get(url)).data
}

export async function getGetInBigMapKeysIn<T> (network: string, contract: string,name: string, keys: (number | string)[]): Promise<{key: string, value: T, active: boolean}[]> {
    const url = createUrlGetInBigMapKeysIn(network, contract, name, keys)
    return (await axios.get(url)).data
}


export async function getContractStorage<T> (network: string, contract: string, path?: string[]): Promise<T> {
    const url = createUrlGetStorage(network, contract, path)
    return (await axios.get(url)).data
}


export async function addressIsOwner(network: string, contract: string, address: string, tokenId: number): Promise<number> {
    const url = `${getBaseUrlTzkt(network)}tokens/balances/count?token.contract=${contract}&account=${address}&token.tokenId=${tokenId}&balance.gt=0`
    return (await axios.get(url)).data
}

function createUrlBlockCount (network: string) {
    return `${getBaseUrlTzkt(network)}blocks/count`
}

function getBaseUrlTzkt(network: string) {
    return `https://api.${network}.tzkt.io/v1/`
}

function createUrl (address: string, network: string, contract: string) {
    return `${getBaseUrlTzkt(network)}tokens/balances?token.contract=${contract}&account=${address}&balance.gt=0&limit=500`
}

function createUrlNftBalanceMinimal (address: string, network: string, contract: string) {
    return `${getBaseUrlTzkt(network)}tokens/balances?token.contract=${contract}&account=${address}&balance.gt=0&limit=500&select=token.tokenId`
}


function createUrlGetBigMaps (network: string, contract: string, limit?: number, offset?: number) {
    let baseUrl = `${getBaseUrlTzkt(network)}bigmaps?contract=${contract}&select=ptr,path`
    if (limit) {
        baseUrl += `&limit=${limit}`

        if (offset) {
            baseUrl += `&offset=${offset}`
        }
    }
    return baseUrl
}


function createUrlGetBigMap (network: string, bigMapId: number, limit=50, offset=0) {
    return `${getBaseUrlTzkt(network)}bigmaps/${bigMapId}/keys?active=true&offset=${offset}&limit=${limit}&select=key,value`
}

function createUrlGetTokenMetadata (network: string, contract: string, tokenId: number) {
    return `${getBaseUrlTzkt(network)}tokens?contract=${contract}&tokenId=${tokenId}`
}

function createUrlGetAllTokenMetadata (network: string, contract: string) {
    return `${getBaseUrlTzkt(network)}tokens?contract=${contract}`
}


function createUrlGetInBigMap (network: string, contract: string, name: string, keys: number | string) {
    return `${getBaseUrlTzkt(network)}contracts/${contract}/bigmaps/${name}/keys/${keys}`
}

function createUrlGetInBigMapKeysIn (network: string, contract: string, name: string, keys: (number | string)[]) {
    // https://api.tzkt.io/v1/contracts/KT1AUTr4hWeAm7xwMTPXd4p3MWcrJy44WmB7/bigmaps/ledger/keys?select=key,value&key.in=2377,1081,731,1743,1705,435,567,167&active=true&limit=10000
    if (keys.length === 1) {
        return `${getBaseUrlTzkt(network)}contracts/${contract}/bigmaps/${name}/keys?select=key,value&key=${keys[0]}&active=true&limit=1000`
    }

    return `${getBaseUrlTzkt(network)}contracts/${contract}/bigmaps/${name}/keys?select=key,value&key.in=${keys.join(',')}&active=true&limit=1000`
}


function createUrlGetStorage (network: string, contract: string, path?: string[]): string {
    let baseUrl = `${getBaseUrlTzkt(network)}contracts/${contract}/storage`
    if (path && path.length) {
        baseUrl += `?path=${path.join('.')}`
    }
    return baseUrl
}
































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ConnectButton from './ConnectButton.vue'
import MenuIcon from 'vue-material-design-icons/Menu.vue';

@Component({
  components: {
    ConnectButton,
    MenuIcon
  },
})
export default class SideBar extends Vue {
    @Prop()
    activeSidebar!: boolean

    closeSideBar () {
      this.activeBar = false
    }

    afterClick () {
      this.$nextTick(() => {
        this.closeSideBar()
      })
    }

    get twitterUrl () {
      return this.$config?.twitterLink
    }

    get discordUrl () {
      return this.$config?.discordLink
    }


    get activeBar () {
      return this.activeSidebar
    }

    set activeBar (value) {
      this.$emit('update:activeSidebar', value)
    }

    active = 'home'

}







































































































import { Component, Model, ModelSync, Prop, Vue, Watch } from 'vue-property-decorator';
import ConnectButton from './ConnectButton.vue'
import TcoinBalance from './TcoinBalance.vue'
import { LIMIT_SCREEN_SMALL, LIMIT_SCREEN_MEDIUM } from '@/constants';
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import { mixins } from 'vue-class-component';
import HasTcoinBalance from '../mixins/HasTcoinBalance';

@Component({
  components: {
    ConnectButton,
    MenuIcon,
    TcoinBalance
  },
})
export default class NavBar extends mixins(HasTcoinBalance) {
    @Prop()
    windowWidth!: number

    @Prop()
    activeSideBar!: boolean

    active = 'guide'

    get smallMode () {
      return this.windowWidth < LIMIT_SCREEN_MEDIUM
    }

    get twitterUrl () {
      return this.$config?.twitterLink
    }

    get discordUrl () {
      return this.$config?.discordLink
    }

    get isEnabledYourTrooperz () {
      return true
    }
    
    @Watch('smallMode')
    onChangeSmallMode () {
      if (!this.smallMode) {
        this.$emit('updateActiveSideBar', false)
      }
    }

}

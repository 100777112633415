

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NetworkType, TezosOperationType, AccountInfo } from '@airgap/beacon-transport-walletconnect/node_modules/@airgap/beacon-types'
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import SideBar from '@/components/SideBar.vue';
import Acknowledgment from '@/components/Acknowledgment.vue';
import { LIMIT_SCREEN_SMALL } from './constants';
@Component({
  components: {
    NavBar,
    Footer,
    SideBar,
    Acknowledgment
  },
})
export default class App extends Vue {
  windowHeight: number = window.innerHeight
  windowWidth: number = window.innerWidth
  sideBarOn = false
  currentAddress: string | null = null
  activeAccount: AccountInfo | null = null
  
  openAck = false

  async mounted() {
      const activeAccount = await this.$wallet.client.getActiveAccount()
      if (activeAccount) {
          this.activeAccount = activeAccount
          this.currentAddress = activeAccount.address
          this.checkAck()
      }
      this.$nextTick(() => {
          window.addEventListener('resize', this.onResize);
      })
  }

  checkAck () {
    const ack = localStorage.getItem('ack')
    if (!ack || ack !== '1') {
      this.openAck = true
      console.warn(this.openAck)
    }
  }

  cancelAck () {
    localStorage.removeItem('ack')
  }

  beforeDestroy() { 
      window.removeEventListener('resize', this.onResize); 
  }

  onResize() {
      console.warn(window.innerHeight)
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
  }

  updateActiveSnackBar (value: boolean) {
    this.sideBarOn = value
  }
  
  get noIndexMode () {
    return this.windowWidth <= LIMIT_SCREEN_SMALL
  }

  @Watch('$store.getters.getAddress')
  onChangeAddress (address: null | string) {
    if (address) {
      this.checkAck()
    } else {
      this.cancelAck()
    }
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuesax from 'vuesax'
import { Extension, TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { Tzip12Module } from '@taquito/tzip12';
import { Tzip16Module } from '@taquito/tzip16';
import config from "../config/config"
import config_dev from "../config/config_dev"
import config_staging from "../config/config_staging"
import VueFullscreen from 'vue-fullscreen'

import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import Carousel3d from 'vue-carousel-3d';
import VueyeTimeline from "vueye-timeline";

library.add(far);
library.add(fas);

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(Carousel3d);
Vue.use(VueFullscreen);
Vue.component('VueyeTimeline', VueyeTimeline);
import 'vuesax/dist/vuesax.css' //Vuesax styles
import { Config } from 'config/config_type'
import { getBeaconNetwork, getPermissionConfig } from './utils/utilities'

function getConfigForEnv (env: string): Config | undefined {
  return ({
    dev: config_dev,
    staging: config_staging,
    prod: config
  } as {[key: string]: Config})[env]
}

const envConfig = getConfigForEnv((process.env.VUE_APP_ENV) as string)
if (!envConfig) {
  throw new Error('No config for this env !')
}
const Tezos = new TezosToolkit(envConfig.rpc);
const options = { name: "Trooperz" , ...getPermissionConfig(envConfig.network, config.rpc)}
const wallet = new BeaconWallet(options);

Tezos.addExtension(<Extension><unknown>(new Tzip16Module()))
Tezos.setWalletProvider(wallet);


declare module "vue/types/vue" {
  interface Vue {
    $vs: any // vuesax custom plugin
    $wallet: typeof wallet,
    $tezos: typeof Tezos,
    $config: typeof envConfig,
    $store: typeof store
  }
}

const darkblue = 'rgb(73, 83, 114)'
const darkdarkblue = 'rgb(18, 18, 18)'
const pink = 'rgb(242, 122, 138)'
const colors = {
  primary: darkblue,
  success: darkdarkblue,
  danger:'rgb(242, 19, 93)',
  warning: pink,
  dark:'rgb(36, 33, 69)'
}


Vue.prototype.$wallet = wallet
Vue.prototype.$tezos = Tezos
Vue.prototype.$config = envConfig
Vue.config.productionTip = false
Vue.use(Vuesax, {
  colors: colors
})

router.beforeEach((to, from, next) => {
  document.title = 'TROOPERZ'
  console.warn(to?.meta?.disabled)
  if (to?.meta?.disabled) {
    next('/')
    return
  }
  next()
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

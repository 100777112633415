





























import { Component, Prop, Vue } from 'vue-property-decorator';

const LOREM =  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ' +
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'

@Component
export default class InfoText extends Vue {
  titleClass = 'cb-neon-blue'
  content = [
    {
      title: 'What is Trooperz?',
      content: LOREM
    }
  ]

  get twitterUrl () {
    return this.$config?.twitterLink
  }

  get discordUrl () {
    return this.$config?.discordLink
  }
}














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import WalletMixin from '../mixins/WalletMixin'
import { getPermissionConfig, subAddress } from '@/utils/utilities';


@Component
export default class ConnectButton extends mixins(WalletMixin) {
  text = 'SYNC'

  async mounted () {
    await this.getText()
  }

  async getText () {
      const activeAccount = await this.$wallet.client.getActiveAccount()
      if (activeAccount?.address) {
        this.address = activeAccount.address
        this.text = this.subAddress(this.address)
      } else {
        this.text = 'SYNC'
      }
  }

  connectOrDisconnect () {
    if (this.address) {
      this.disconnect()
    } else {
      this.connect()
    }
  }

  async disconnect () {
    await this.$wallet.clearActiveAccount()
    this.address = null
  }

  subAddress (address: string): string {
    return subAddress(address)
  }  

  overButton () {
    if (this.address) {
      this.text = 'UNSYNC'
    } else {
      this.text = 'SYNC'
    }
  }

  leaveButton () {
    if (this.address) {
      this.text = this.subAddress(this.address)
    } else {
      this.text = 'SYNC'
    }
  }

  async connect() {
      await this.requestPermissions()
      const activeAccount = await this.$wallet.client.getActiveAccount()
      if (activeAccount?.address) {
        this.address = activeAccount.address
        this.text = this.subAddress(this.address)
      } else {
        this.text = 'SYNC'
      }
  }

  get address () {
    return this.$store.getters.getAddress
  } 

  set address (address: string | null) {
    this.$store.commit('setAddress', address)
  }

  @Watch('address', {deep: true})
  onChangeAddress () {
    console.warn('address updated')
    if (this.address) {
      this.text = this.subAddress(this.address)
    } else {
      this.text = 'SYNC'
    }
  }
}

import { Config } from "./config_type";

export default  {
    rpc: 'https://ghostnet.ecadinfra.com',
    marketplaceContractAddress: 'KT1D2gV16GcBaV6vReQpRLQCymqN9BDw68Vr',
    tokenContractAddress: 'KT1DEVfmoEzcxHN9YXWVLdkwH6CXkZ2q6HgW',
    comicsTokenAddress: 'KT1PNshnJA7KrrKRxU1orkbFo3wts18ueHo9',
    marketplaceMulti: 'KT1HbXNeiq6LpNLFER8z6mWWhwRZRiQRodat',
    trainingRoomAddress: 'KT1RdvSZztGCeDXsre9GQqeExE4UnesxjWdg',
    trooperzMarketplaceAddress: 'KT1GWUYnqDzpLQkeCjmS1cr8cLGDmtD3rBnp',
    tcoinAddress: 'KT1UA1TrMmHne9vTVNsFyHUsEe9cwTSV8QEf',
    price: 5,
    nbTrooperz: 200,
    saleLaunched: true,
    twitterLink: 'https://twitter.com/TrooperzGenesis',
    discordLink: 'https://discord.gg/DPve7S5zTJ',
    networkBetterCallDev: 'ithacanet',
    networkTzkt: 'ghostnet',
    allowSync: true,
    network: 'ghostnet',
    pricePresale: 4,
    nbDigitTcoin: 8,
    hasTrainingRoom: true,
    timeBlock: 8,
    uniqueTrooperz: [],
    oldContracts: [],
    contractBooster: 'KT1A5T36NyZaf5CB6naYHe4j2gbbnw3cH9cU',
    contractUseConsumables: 'KT1SZ8JtddxYyxNd29kyYhaWuJMdUbWmN1Lu',
    contractArtwork: 'KT1XBwi1Khyrsf1PunVeyoj4e2s8XbtkxGLd',
    contractBank: 'KT1WEJ3fvnck2aqXcFiy8ZGN64EXAdie193W'
} as Config
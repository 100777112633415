import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
const Home = () =>  import('../views/Home.vue')
const MyTrooperz = () =>  import('../views/MyTrooperz.vue')
const Trooperz = () =>  import('../views/Trooperz.vue')
const RarityChecker = () => import('../views/RarityChecker.vue')
const Marketplace = () =>  import('../views/Marketplace.vue')
const Bank = () => import('../views/Bank.vue')
const Comics = () =>  import('../components/Comics.vue')

const Artwork = () => import('../components/Artwork/Artwork.vue')
const Consumables = () => import('../components/Consumables/Consumables.vue')
const YourTrooperz = () => import('../components/YourTrooperz.vue')

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/my-trooperz',
    component: MyTrooperz,
    meta: {
      disabled: false
    },
    children: [
      {
        path: '',
        name: 'MyTrooperz',
        component: YourTrooperz,
      },
      {
        path: 'comics',
        name: 'comics',
        component: Comics,
      },
      {
        path: 'artwork',
        name: 'artwork',
        component: Artwork,
      },
      {
        path: 'consumables',
        name: 'consumables',
        component: Consumables,
      }
    ]
  },
  {
    path: '/trooperz/:tokenId',
    name: 'Trooperz',
    component: Trooperz,
    props: true,
    meta: {
      disabled: false
    }
  },
  {
    path: '/galleryV0',
    name: 'RarityChecker',
    component: RarityChecker,
    props: true,
    meta: {
      disabled: false
    }
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: Marketplace,
    props: true,
    meta: {
      disabled: false
    }
  },
  {
    path: '/staking',
    name: 'Bank',
    component: Bank,
    props: true,
    meta: {
      disabled: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

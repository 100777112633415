import { Config } from "./config_type";

export default {
    rpc: 'http://localhost:20000',
    marketplaceContractAddress: 'KT1TjSTnyaHmyNeYf4Ee4oQcV8NQGTnwf7Px',
    tokenContractAddress: 'KT1Ge2rtexBfNUU2o84arENft7nYspUnVGrg',
    comicsTokenAddress: '',
    marketplaceMulti: '',
    trainingRoomAddress: '',
    trooperzMarketplaceAddress: 'KT1QANAbXtHg8nfkxVLKcb26RAJi8P1n8V4G',
    tcoinAddress: 'KT1SDP1PRPzYE9SpvcE4wPgDgB249QziL5Y9',
    price: 5,
    nbTrooperz: 40,
    saleLaunched: true,
    twitterLink: 'https://twitter.com/TrooperzGenesis',
    discordLink: 'https://discord.gg/DPve7S5zTJ',
    networkBetterCallDev: 'hangzhou2net',
    networkTzkt: 'hangzhou2net',
    allowSync: true,
    network: 'hangzhou',
    pricePresale: 4,
    nbDigitTcoin: 8,
    hasTrainingRoom: false,
    timeBlock: 15,
    uniqueTrooperz: [],
    oldContracts: [],
    contractBooster: 'KT1A5T36NyZaf5CB6naYHe4j2gbbnw3cH9cU',
    contractUseConsumables: 'KT1GyHvM7fEn4HmRh5fSUs3u943bsFt3wTqo',
    contractArtwork: 'KT1XBwi1Khyrsf1PunVeyoj4e2s8XbtkxGLd',
    contractBank: 'KT1WEJ3fvnck2aqXcFiy8ZGN64EXAdie193W'
} as Config
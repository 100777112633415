import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Accessors } from 'vue/types/options';
import { getTokenBalanceOfUser } from '@/apiCaller/tzktApi';
import { convertValueFromContract } from '@/utils/utilities';

@Component
export default class HasTcoinBalance extends Vue {
    mounted () {
        this.getTcoinBalance()
    }

    async getTcoinBalance () {
        const config = this.$config
        if (!config) {
            console.warn('Can not find config')
            throw Error('Can not find config')
        }
        const activeAccount = await this.$wallet.client.getActiveAccount();
        if (!activeAccount) {
            console.warn('$TCOIN balance : no active account')
            this.tcoinBalance = 0
            return
        }
        const address = activeAccount.address
        const response = await getTokenBalanceOfUser(address, config.networkTzkt, config.tcoinAddress)
        if (response) {
            if (response.data.length) {
                this.tcoinBalance = convertValueFromContract(Number(response.data[0].balance), config.nbDigitTcoin) 
            } else {
                this.tcoinBalance = 0
            }
        } else {
            console.warn(`Response error : `, response)
            this.tcoinBalance = 0
        }
    }

    get tcoinBalance () {
        return this.$store.getters.getTcoinBalance
    }

    set tcoinBalance (value: number) {
        this.$store.commit('setTcoinBalance', value)
    }

    @Watch('$store.getters.getAddress', {deep: true})
    onChangeAddress () {
        this.getTcoinBalance()
    }
}



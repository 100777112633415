import { Config } from "./config_type";

export default  {
    rpc: 'https://mainnet.ecadinfra.com',
    marketplaceContractAddress: 'KT1HARGWmKVZZKyni3AeDnMuL6aeD3c7BTVt',
    tokenContractAddress: 'KT1BYKSPZxtmLQrJKZW455qzvKbsTGrcuuAR',
    comicsTokenAddress: 'KT1UfAtbXc31jkwiJfv7jDAh9DDBxK7C2oeH',
    marketplaceMulti: 'KT1H5GWkQ9VrJ3tgbhxyT5LRHWU7wnefWJB9',
    trainingRoomAddress: 'KT1NRLjyE7wxeSZ6La6DfuhSKCAAnc9Lnvdg',
    trooperzMarketplaceAddress: 'KT1JUe2oyfM76GEoWJEGvCKH1nz72KYq7Jub',
    tcoinAddress: 'KT1GorwGk5WXLUc3sEWrmPLQBSekmYrtz1sn',
    price: 5,
    nbTrooperz: 3201,
    saleLaunched: true,
    twitterLink: 'https://twitter.com/TrooperzGenesis',
    discordLink: 'https://discord.gg/DPve7S5zTJ',
    networkBetterCallDev: 'mainnet',
    networkTzkt: 'mainnet',
    allowSync: true,
    network: 'mainnet',
    pricePresale: 4,
    nbDigitTcoin: 8,
    hasTrainingRoom: true,
    timeBlock: 10,
    uniqueTrooperz: [745,43,582,358,2736,110,1310],
    oldContracts: [],
    contractBooster: 'KT1BAhDLdb38kwLjHecyNNAp18jAKbgPiTQu',
    contractUseConsumables: 'KT1DPSWEPfhR3KMScg54Q19jes3kULhjiDzy',
    contractArtwork: 'KT1Ps1RH6CRkZiG1Tfr8f3tyzWdH4JguWfEQ',
    contractBank: 'KT1QMf2QBwEt9HXDvZd5X9yB1NneMfPYZaag'
} as Config
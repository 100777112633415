import { Component, Prop, Vue } from 'vue-property-decorator';
import { NetworkType, TezosOperationType, PermissionResponseOutput } from '@airgap/beacon-transport-walletconnect/node_modules/@airgap/beacon-types'
import { getBeaconNetwork, getPermissionConfig } from '@/utils/utilities';

@Component
export default class WalletMixin extends Vue {
    permission: PermissionResponseOutput | null = null
    async getCurrentAccountAndRequestPermissionsIfNot () {
        let currentAddress
        const activeAccount = await this.$wallet.client.getActiveAccount();
        const config = this.$config
        if (!config) {
            throw Error('No config !')
        }
        if (!activeAccount) {
            const permissions = await this.requestPermissions()
            currentAddress = permissions?.address
        } else {
            currentAddress = activeAccount.address
        }
        return currentAddress
    }

    async requestPermissions () {
        if (!this.$config) {
            throw Error('No config !')
        }
        try {
            const permissions = await this.$wallet.client.requestPermissions();
            this.permission = permissions
            this.$root.$data.currentAddress = permissions.address
            this.$root.$data.activeAccount = await this.$wallet.client.getActiveAccount()
            return permissions
        } catch (error) {
            console.log("Got error:", error);
        }
        return null
    }
    disconnect () {
        this.$wallet.clearActiveAccount()
        this.permission = null
    }
}


